/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* mat date datetime picker*/

ngx-mat-datepicker-content {

    border: 1px solid rgba(var(--ion-color-primary-rgb), 0.2);

    background: #fff;

    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    .mat-calendar-body-cell-content {

        border: 0px;

    }

    .mat-calendar-body-selected {

        background: rgba(var(--ion-color-primary-rgb), 0.2);

        border: 1px solid rgba(0, 0, 0, 0.2);

        color: #333;

    }

    .mat-calendar-body-disabled .mat-calendar-body-cell-content {

        background: #dadada;

        color: #fff;

    }

    .mat-calendar-table-header {

        th {

            text-align: center;

            padding: 0 0 8px;

            font-weight: normal;

            font-size: 11px;

            opacity: 0.6;

        }

        .mat-calendar-table-header-divider {

            border-top: 1px solid rgba(0, 0, 0, 0.1);

        }

    }

    .mat-calendar-body-label {

        font-size: 14px;

        font-weight: bold;

        opacity: 0.5;

    }

    .mat-calendar-body-today {

        background: rgba(var(--ion-color-primary-rgb), 0.2);

    }


}

.pre-wrap {
    white-space: pre-wrap;
}

.bold {
    font-weight: bold;
}

.info-message {
    padding: 0px 5px;
}

// messaggi di errore e warning 
.wizard-form-warning {
    color: var(--ion-color-warning);
    font-size: 14px;
}

.wizard-form-danger {
    color: var(--ion-color-danger);
    font-size: 14px;
}

// non ionic labels 
.labels-dark {
    color: var(--ion-color-dark);
}

//error message
.error-message {
    color: var(--ion-color-danger);
    font-size: 12px;
    line-height: 13px;
}

.info-message {
    padding: 10px 0px;
}

//delete field
.delete-field {
    font-size: 16px;
    position: absolute;
    top: 13px;
    right: 0px;
    visibility: hidden;
    margin: 0;
    z-index: 10;
}

ion-item:hover {
    .delete-field {
        visibility: visible !important;
    }
}

.disabled {
    opacity: 0.3;
}

.date-format {
    font-size: 14px;
    color: #949494;
    padding-left: 5px;
}

ion-label {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.toll-instructions {
    padding: 0px 10px;
}

.ion-card-title-left {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}

/* prompt */
.transaction-detail-prompt {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 500px !important;
        max-height: 90vh !important;
    }
}

.input-prompt {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh !important;
    }
}

.user-detail-prompt {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh !important;
    }
}

.associated-customer-prompt {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh !important;
    }
}

.department-detail-prompt {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh !important;
    }
}

.customer-detail-prompt {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh !important;
    }
}

.vehicles-detail-prompt {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh !important;
    }
}

.company-change-prompt {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh !important;
    }
}

/* detail fields */
.detail-header-field {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .df-label {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--ion-color-primary);
        font-size: 18px;
        line-height: 19px;
        word-break: break-all;

        ion-icon {
            font-size: 30px;
            min-width: 30px;
            color: var(--ion-color-primary);
            margin-right: 10px;
        }
    }

    .df-value {
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-end;
        text-align: right;
        font-size: 18px;
        line-height: 19px;
        word-break: break-all;
    }
}

.detail-row {
    padding-top: 10px;
}

.detail-field {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    line-height: 16px;
    padding: 5px;
    width: 100%;

    .df-label {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        line-height: 17px;
        word-break: break-all;
        color: #333;
        min-width: 100px;
    }

    .df-value {
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-end;
        text-align: right;
        word-break: break-all;
    }
}

@media only screen and (max-width: 500px) {
    .detail-header-field {
        .df-label {
            ion-icon {
                display: none !important;
            }
        }
    }
}

.empty-list {
    padding: 10px;
}

/* override fix group header*/
.group-header {
    padding: 0px 15px;
    justify-content: space-between !important;
    flex-direction: row !important;

    span {
        margin: 0px !important;
        width: auto !important;
    }
}

/* app-plate-input */
app-plate-input {
    width: 100%;
}

/* massive import */
.massive-import {
    width: 100%;

    .file-load-row-count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 18px;
    }

    .massive-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 5px;

        .massive-item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;

            .massive-item-top {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                .massive-title {
                    color: var(--ion-color-primary-contrast);
                    font-size: 20px;
                    background: var(--ion-color-primary);
                    padding: 10px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;

                    ion-icon {
                        font-size: 20px;
                        color: var(--ion-color-primary-contrast);
                        margin-right: 5px;
                    }
                }
                .massive-title-danger{
                    color: var(--ion-color-danger-contrast);
                    background: var(--ion-color-danger);
                }
            }

            .massive-item-bottom {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                border: 1px solid rgba(var(--ion-color-primary-rgb), 0.2);
                margin-bottom: 10px;
                position: relative;
                background: var(--ion-color-card-bg);

                .delete-item {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: var(--ion-color-danger);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    ion-icon {
                        font-size: 20px;
                        color: var(--ion-color-danger-contrast);
                    }
                }

                .row-list {
                    min-height: 90px;
                    padding: 15px;
                }

                .input-plate {
                    outline: none;
                    background: transparent;
                    width: 100%;
                    height: 38px;
                    border: 1px solid #cccccc;
                    border-radius: 5px;
                    padding: 0px 8px;
                    position: relative;

                    input {
                        width: 100%;
                        outline: none;
                        border: 0px;
                    }
                }

                .input-plate::before {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: -1px;
                    left: auto;
                    background-color: white;
                    right: 0;
                    box-shadow: -5px -8px 9px 4px white;
                }

                .input-plate::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: auto;
                    background-color: white;
                    left: 0px;
                    bottom: -1px;
                    box-shadow: 5px 8px 9px 4px white;
                }


                .input-uppercase {
                    text-transform: uppercase;
                }
            }
        }
    }

    ion-row {
        width: 100%;
    }
}

/* input-text-readonly */
.input-text-readonly {
    width: 100%;
    white-space: normal;
    word-break: break-word;
    min-height: 38px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* app-table */
app-table {
    width: 100%;
}

/* mat tabs */
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: var(--ion-color-primary) !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--ion-color-primary) !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0 !important;
}

.mat-mdc-tab-labels {
    padding: 0px 20px !important;
}

/*button file*/
.button-file {
    height: 38px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.button-file-empty {
    span {
        opacity: 0.2;
    }
}

/* datalist */
.input-field {
    .input-datalist {
        border: 1px solid #cccccc;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        font-size: 14px;
        position: relative;
        width: 100%;

        input {
            border: 0px;
            outline: none;
            width: 100%;
            padding: 0px 8px;
            background: transparent;
        }
    }

    .input-datalist::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: -1px;
        left: auto;
        right: 0;
        background: linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(255, 255, 255, 1) 42%);
    }

    .input-datalist::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: auto;
        left: 0px;
        bottom: -1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 58%, rgba(204, 204, 204, 1) 100%);
    }
}
app-address-form{
    display: contents;
}

.input-disabled{
    position: relative;
    mat-datepicker-toggle{
        display: none;
    }
}
.input-disabled::after{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: not-allowed;
    pointer-events: all;
}

.section-readonly{
    .action-td{
        display: none;
    }
}

/* richiesti headers sticky 06/12/2024*/
.table-container{
    max-height: 600px !important;
    thead{
        position: sticky;
        top: 0;
        z-index: 1;
    }
}

